/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
.hathor-orderbook {
  font-size: 13px;
  display: inline-block;
  color: var(--row-color);
  padding: 16px;
}
.hathor-orderbook__side-header {
  margin: 0 0 5px 0;
  font-weight: 700;
  text-align: right;
}
.hathor-orderbook__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.hathor-orderbook__list-item {
  cursor: pointer;
  padding: 3px 50px 3px 20px;
  display: flex;
}
.hathor-orderbook__list-item:hover {
}
.hathor-orderbook__price {
  flex: 0 0 16px;
  color: var(--row-color);
  text-align: right;
  display: inline-block;
  margin-right: 15px;
}
.hathor-orderbook__size {
  flex: 0 0 70px;
}
.hathor-orderbook__spread {
  border-width: 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  padding: 5px 20px;
  text-align: center;
  display: flex;
}
.hathor-orderbook__spread-header {
  margin: 0 15px 0 0;
  text-align: right;
}
.hathor-orderbook__spread-value {
  overflow: hidden;
}
